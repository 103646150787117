export default {
  name: "UsersTable",
  props: {
    headers: Array,
    items: Array,
    editedItem: Object,
    loading: Boolean,
  },
  data() {
    return {
      search: "",
      dialogEdit: false,
      dialogNew: false,
      dialogRemove: false,
      editedIndex: -1,

      menuFrom: false,
      menuTo: false,

      formEditValid: false,
      formEditLoading: false,

      formNewValid: false,
      formNewLoading: false,

      editedItemInner: JSON.parse(JSON.stringify(this.editedItem)),
      defaultItemInner: JSON.parse(JSON.stringify(this.editedItem)),

      itemToRemove: null,
      valid: false,

      user: {
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        country: "",
        code: "",
        phone: "",
        food: false,
      },

      firstNameRules: [
        (v) => !!v || "First name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
      lastNameRules: [
        (v) => !!v || "Last name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      companyRules: [
        (v) => !!v || "Company is required",
        (v) => v.length <= 30 || "Company must be less than 30 characters",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length <= 20 || "Phone must be less than 20 characters",
      ],
      dateFromRules: [(v) => !!v || "Date is required"],
      dateToRules: [(v) => !!v || "Date is required"],

      statuses: [
        {
          id: 0,
          name: "On site",
        },
        {
          id: 1,
          name: "Remote",
        },
        {
          id: 2,
          name: "Canceled",
        },
      ],

      menu: false,
      modal: false,
    };
  },

  computed: {
    getLanguage() {
      // console.warn(this.editedItemInner.data.country);
      return this.editedItemInner.data.country;
    },
  },

  watch: {
    dialogEdit(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    initialize() {},
    getStatus(status) {
      let result = "";
      switch (status) {
        case 0:
          result = "On site";
          break;
        case 1:
          result = "Remote";
          break;
        case 2:
          result = "Canceled";
          break;
      }
      return result;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItemInner = Object.assign({}, item);

      /*
      console.warn({
        editItem: item,
        editedIndex: this.editedIndex,
        editedItemInner: this.editedItemInner,
      });
      */

      this.dialogEdit = true;
    },
    removeItem(item) {
      // console.warn(item);

      this.itemToRemove = item;

      this.dialogRemove = true;
    },
    onRemoveItem() {
      this.$emit("onRemove", {
        data: this.itemToRemove.data,
      });

      this.closeRemove();
    },

    close() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItemInner = Object.assign({}, this.defaultItemInner);
        this.editedIndex = -1;
      });
    },
    closeNew() {
      this.dialogNew = false;
      this.user = {
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        country: "",
        code: "",
        phone: "",
        dateFrom: null,
        dateTo: null,
        food: false,
      };
    },
    saveEdit() {
      this.$refs.formEdit.validate();

      if (this.formEditValid) {
        if (this.editedIndex > -1) {
          this.$emit("onUpdate", {
            data: this.editedItemInner,
          });

          Object.assign(this.items[this.editedIndex], this.editedItemInner);
        }

        this.close();
      }
    },
    saveNew() {
      this.$refs.formNew.validate();

      if (this.formNewValid) {
        this.user.eventId = this.$route.params.id;

        this.$emit("onInsert", {
          data: this.user,
        });

        this.closeNew();
      }
    },
    // eslint-disable-next-line
    onSelect({ name, iso2, dialCode }) {
      // console.warn(name, iso2, dialCode);

      this.editedItemInner.data.country = iso2;
      this.editedItemInner.data.code = dialCode;
    },
    // eslint-disable-next-line
    onSelectNew({ name, iso2, dialCode }) {
      // console.warn(name, iso2, dialCode);

      this.user.country = iso2;
      this.user.code = dialCode;
    },
    closeRemove() {
      this.dialogRemove = false;
    },
    onNewUserClick() {
      this.dialogNew = true;
    },
  },
};
