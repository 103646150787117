/* eslint-disable */
import { relativeTimeThreshold } from "moment";
import Setting from "../../setting.js";
import { useAppStore } from "../stores/main";

export default {
  name: "MeetingsView",
  components: {},
  data: () => ({
    store: useAppStore(),
    loading: false,

    formNewValid: false,
    formNewLoading: false,

    formEditValid: false,
    formEditLoading: false,

    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 100 || "Name must be less than 50 characters",
    ],

    placeRules: [
      (v) => !!v || "Place is required",
      (v) => v.length <= 50 || "Place must be less than 50 characters",
    ],

    dateFromRules: [(v) => !!v || "Date is required"],
    dateToRules: [(v) => !!v || "Date is required"],

    wikiRules: [(v) => !!v || "Wiki is required"],

    zoneRules: [(v) => !!v || "Zone is required"],
    zoneNameRules: [(v) => !!v || "Zone name is required"],

    menuNewFrom: null,
    menuNewTo: null,
    menuEditFrom: null,
    menuEditTo: null,

    newMeeting: false,
    editMeeting: false,
    dialogUpdating: false,
    meetingModel: {
      name: "",
      place: "",
      dateFrom: null,
      dateTo: null,
      wiki: null,
      timeZone: null,
      isOpen: false,
    },

    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
    ],
    meetingsHeaders: [
      {
        text: "Id",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Place",
        align: "start",
        sortable: true,
        value: "place",
      },
      {
        text: "From",
        align: "center",
        sortable: true,
        value: "dateFrom",
      },
      {
        text: "To",
        align: "center",
        sortable: true,
        value: "dateTo",
      },
      {
        text: "Open",
        align: "center",
        sortable: true,
        value: "isOpen",
      },
      {
        text: "Actions",
        align: "end",
        sortable: false,
        value: "actions",
      },
    ],
    meetingsItems: [],
    deleteDialog: false,
    meetingToDelete: null,
  }),
  methods: {
    onBreadClick(event) {
      if (event.href) this.$router.push(event.href);
    },
    async getData() {
      this.loading = true;
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "Admin/GetAllMeeting",
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    onMeeting(event) {
      this.$router.push({ name: "meeting", params: { id: event.id } });
    },
    onUsers(event) {
      this.$router.push({ name: "users", params: { id: event.id } });
    },
    onEdit(event) {
      this.meetingModel = JSON.parse(JSON.stringify(event));

      this.editMeeting = true;
    },
    onDelete(event) {
      this.meetingToDelete = event;
      this.deleteDialog = true;
    },
    async confirmDelete() {
      let result = await this.axios.delete(
        Setting[process.env.NODE_ENV].API + "Admin/DeleteMeeting",
        {
          data: { id: this.meetingToDelete.id },
          headers: {
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );

      if (result.data.success) {
        let meetings = await this.getData();
        this.loading = false;
        this.meetingsItems = meetings.data.data;
        this.$toast.success("Meeting byl úspěšně smazán");
      }
      this.deleteDialog = false;
      this.meetingToDelete = null;
    },
    cancelDelete() {
      this.deleteDialog = false;
      this.meetingToDelete = null;
    },
    onNewMeeting() {
      this.meetingModel = {
        name: "",
        place: "",
        dateFrom: null,
        dateTo: null,
        wiki: null,
        timeZone: null,
        isOpen: false,
      };

      this.newMeeting = true;
    },
    async onCreateMeeting() {
      this.$refs.formNew.validate();

      if (this.formNewValid) {
        this.formNewLoading = true;

        let result = await this.axios.post(
          Setting[process.env.NODE_ENV].API + "Admin/CreateMeeting",
          this.meetingModel,
          {
            headers: {
              "Access-Control-Allow-Origin":
                Setting[process.env.NODE_ENV].Source,
            },
          }
        );

        this.formNewLoading = false;

        if (result.data.success) {
          let mettings = await this.getData();
          this.loading = false;
          this.meetingsItems = mettings.data.data;
          this.newMeeting = false;
        }
      }
    },
    async onEditMeeting() {
      this.$refs.formEdit.validate();

      if (this.formEditValid) {
        this.formEditLoading = true;

        let result = await this.axios.post(
          Setting[process.env.NODE_ENV].API + "Admin/UpdateMeeting",
          this.meetingModel,
          {
            headers: {
              "Access-Control-Allow-Origin":
                Setting[process.env.NODE_ENV].Source,
            },
          }
        );

        this.formEditLoading = false;

        if (result.data.success) {
          let mettings = await this.getData();
          this.loading = false;
          this.meetingsItems = mettings.data.data;
          this.editMeeting = false;
        }
      }
    },
    onCloseDialog() {
      this.newMeeting = false;

      this.meetingModel = {
        name: "",
        place: "",
        dateFrom: null,
        dateTo: null,
        wiki: null,
        timeZone: null,
        isOpen: false,
      };
    },
    onCloseEditDialog() {
      this.editMeeting = false;

      this.meetingModel = {
        name: "",
        place: "",
        dateFrom: null,
        dateTo: null,
        wiki: null,
        timeZone: null,
        isOpen: false,
      };
    },
    GetRegistrationLink() {
      return (
        Setting[process.env.NODE_ENV].RegistrationLink + this.meetingModel.id
      );
    },
    copyRegistrationLink() {
      const text =
        Setting[process.env.NODE_ENV].RegistrationLink + this.meetingModel.id;
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Volitelně můžete přidat notifikaci o úspěšném zkopírování
          this.$toast.success("Odkaz byl zkopírován do schránky");
        })
        .catch((err) => {
          console.error("Chyba při kopírování:", err);
        });
    },
  },
  async created() {
    let mettings = await this.getData();

    this.loading = false;

    // console.warn(mettings.data.data);

    this.meetingsItems = mettings.data.data;
  },
  async mounted() {
    if (!this.store.getAuth) {
      this.$router.push("/");
    }
  },
};
