/* eslint-disable */
export default {
  development: {
    API: "http://localhost:5245/api/", // API: "https://localhost:7245/api/" http://localhost:5245/api/ https://f2fmeeting-dev.azurewebsites.net/api/  https://f2f-meetings-api.app-o-ran.org/api/
    Source: "http://localhost:5245/api/",
    RegistrationLink: "http://localhost:5245?id=",
  },
  production: {
    API: "https://f2f-meeting-prod.azurewebsites.net/api/", //  https://f2f-meeting-prod.azurewebsites.net/api/ https://f2fmeeting-dev.azurewebsites.net/api/  https://f2f-meetings-api.app-o-ran.org/api/
    Source: "https://f2f-meetings-admin.app-o-ran.org/", // https://polite-forest-010c92603.2.azurestaticapps.net/  https://white-hill-054ae5503.2.azurestaticapps.net/
    RegistrationLink: "ambitious-island-0861f2d03.1.azurestaticapps.net?id=", // https://purple-hill-02598f303.1.azurestaticapps.net?id=   ambitious-island-0861f2d03.1.azurestaticapps.net?id=
  },
};
