import { render, staticRenderFns } from "./MeetingView.vue?vue&type=template&id=bbb0844a&"
import script from "./MeetingView.js?vue&type=script&lang=js&"
export * from "./MeetingView.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports