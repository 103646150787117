/* eslint-disable */
import { relativeTimeThreshold } from "moment";
import Setting from "../../setting.js";
import { useAppStore } from "../stores/main";

export default {
  name: "MeetingView",
  components: {},
  data: () => ({
    store: useAppStore(),
    loading: false,
    itemTemplateData: null,
    itemTemplateLocations: null,
    validFormNew: false,
    validFormUpdate: false,
    validFormMove: false,
    dialogUpdating: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Meetings",
        disabled: false,
        href: "/meetings",
      },
    ],

    deleteNotifications: false,

    titleRules: [
      (v) => !!v || "Title is required",
      (v) => (!!v && v.length > 2) || "Tile must be more then 2 characters",
    ],
    locationRules: [(v) => !!v || "Location is required"],
    fromRules: [(v) => !!v || "From is required"],
    toRules: [(v) => !!v || "From is required"],
    colorRules: [(v) => !!v || "Color is required"],
    roomRules: [(v) => !!v || "Room is required"],
    dateRules: [(v) => !!v || "Date is required"],

    file: null,
    dayImport: null,

    scheduleNewDialog: false,
    scheduleDeleteDialog: false,
    scheduleUpdateDialog: false,
    scheduleMoveDialog: false,
    locationsDialog: false,
    scheduleRegistrationsDialog: false,
    scheduleImportDialog: false,

    scheduleImportValid: false,
    scheduleImportLoading: false,
    fileUploadError: false,

    location: {
      name: null,
      order: null,
    },

    colors: [
      "#404040",
      "#0b6db3",
      "#396a31",
      "#df2719",
      "#422f76",
      "#7f7f7f",
      "#3b8ac2",
      "#61885a",
      "#e55247",
      "#685991",
      "#867aa7",
      "#a49bbd",
      "#c2bcd3",
      "#a6a6a6",
      "#6ca7d1",
      "#aec234",
      "#ef938c",
      "#b12984",
      "#f4ae1b",
      "#c0539c",
      "#f6be48",
      "#d07eb5",
      "#f8ce76",
      "#e0a9ce",
      "#fbdfa3",
      "#efd4e6",
      "#fdefd1",
      "#bfbfbf",
      "#9dc4e1",
      "#ceda85",
      "#f5beba",
      "#d9d9d9",
      "#cee2f0",
      "#d8e5bd",
      "#f9d4d1",
    ],

    schedulesColors: [],

    dates: [
      {
        value: "2023-02-13T00:00:00",
        text: "2023-02-13",
      },
      {
        value: "2023-02-14T00:00:00",
        text: "2023-02-14",
      },
      { value: "2023-02-15T00:00:00", text: "2023-02-15" },
      { value: "2023-02-16T00:00:00", text: "2023-02-16" },
      { value: "2023-02-17T00:00:00", text: "2023-02-17" },
    ],

    timeFrom: ["08:30", "10:15", "13:00", "14:45", "16:30", "18:15"],
    timeTo: ["10:00", "11:45", "14:30", "16:15", "18:00", "19:45"],

    scheduleRegistrations: [],

    registrationsHeaders: [
      {
        text: "Email",
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: "First Name",
        align: "start",
        sortable: true,
        value: "firstName",
      },
      {
        text: "Last Name",
        align: "start",
        sortable: true,
        value: "lastName",
      },
      {
        text: "Company",
        align: "start",
        sortable: true,
        value: "company",
      },
      {
        text: "Status",
        align: "start",
        sortable: true,
        value: "status",
      },
    ],

    meetingHeaders: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
    ],

    schedulesHeaders: [
      {
        text: "Title",
        align: "start",
        sortable: true,
        value: "title",
      },
      {
        text: "Location",
        align: "start",
        sortable: true,
        value: "location",
      },
      {
        text: "From",
        align: "start",
        sortable: true,
        value: "time.from",
      },
      {
        text: "To",
        align: "start",
        sortable: true,
        value: "time.to",
      },

      {
        text: "Color",
        align: "start",
        sortable: true,
        value: "color",
      },
      {
        text: "Actions",
        align: "end",
        sortable: true,
        value: "actions",
      },
    ],

    meeting: null,
    schedules: null,
    meetingDates: [],
  }),
  methods: {
    onBreadClick(event) {
      if (event.href) this.$router.push(event.href);
    },
    async getData() {
      this.loading = true;
      let metting = await this.getMeeting();
      let schedules = await this.getSchedules();
      let schedulesColors = await this.getSchedulesColors();

      this.loading = false;

      this.meeting = metting.data.data;
      this.schedules = schedules.data.data;
      this.schedulesColors = schedulesColors.data.data;

      this.meetingDates = this.meeting.data;

      /*
      console.warn({
        meeting: this.meeting,
        schedules: schedules.data.data,
      });
      */
    },
    async getMeeting() {
      //console.warn(this.$route.params.id);

      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "Admin/GetMeeting",
        {
          // withCredentials: true,
          params: {
            id: this.$route.params.id,
          },
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async getSchedules() {
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "Admin/GetAllSchedules?",
        {
          // withCredentials: true,
          params: {
            meetingId: this.$route.params.id,
          },
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    async getSchedulesColors() {
      return await this.axios.get(
        Setting[process.env.NODE_ENV].API + "Admin/GetAllSchedulesColors?",
        {
          // withCredentials: true,
          params: {
            meetingId: this.$route.params.id,
          },
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );
    },
    getColors(color) {
      let item = this.schedulesColors.find((x) => x.color == color);

      return item ? item.titles : "";
    },
    onDate(event) {
      // console.warn(event);
    },
    getSchedulesByDate(event) {
      const schedules = this.schedules.filter((x) => x.date == event);

      // console.warn(schedules);

      return schedules ? schedules : [];
    },
    scheduleUpdate(event) {
      // console.warn(event);

      // alert("onScheduleUpdate: " + event.id);

      this.scheduleUpdateDialog = true;
      this.itemTemplateData = JSON.parse(JSON.stringify(event));

      /*
      console.warn({
        scheduleUpdate: this.itemTemplateData,
      });
      */
    },
    scheduleDelete(event) {
      // alert("onScheduleDelete: " + event.id);

      this.scheduleDeleteDialog = true;

      this.itemTemplateData = JSON.parse(JSON.stringify(event));
    },
    scheduleMove(event) {
      // alert("onScheduleMove: " + event.id);

      this.scheduleMoveDialog = true;
      this.itemTemplateData = JSON.parse(JSON.stringify(event));

      /*
      console.warn({
        scheduleMove: this.itemTemplateData,
      });
      */
    },
    onNew(event) {
      this.scheduleNewDialog = true;

      this.itemTemplateData = JSON.parse(JSON.stringify(event));
      //alert("onNew: " + event);

      /*
      console.warn({
        onNew: this.itemTemplateData,
      });
      */
    },
    async onSaveScheduleNew() {
      /*
      console.warn({
        onSaveScheduleNew: {
          data: this.itemTemplateData,
          date: this.itemTemplateData.date,
          room: this.itemTemplateData.room,
          meeting: this.meeting.data,
        },
      });
      */

      this.dialogUpdating = true;

      let result = await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Admin/ScheduleAdd",
        {
          meeting: this.$route.params.id,
          title: this.itemTemplateData.title,
          color: this.itemTemplateData.color,
          location: this.meeting.data
            .find((x) => x.date == this.itemTemplateData.date)
            .rooms.find((x) => x.id == this.itemTemplateData.room).name,
          room: this.itemTemplateData.room,
          date: this.itemTemplateData.date,
          time: {
            from: this.itemTemplateData.from,
            to: this.itemTemplateData.to,
          },
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );

      /*
      console.warn({
        onSaveScheduleNew: result,
      });
      */

      this.dialogUpdating = false;

      if (result.data.success) {
        this.itemTemplateData = null;
        this.scheduleNewDialog = false;
        await this.getData();
      } else {
        result.data.errors.forEach((element) => {
          this.$toast.open({
            message: element,
            type: "error",
          });
        });
      }
    },
    onCloseScheduleNew() {
      this.scheduleNewDialog = false;
    },
    async onDeleteSchedule() {
      // console.warn(this.itemTemplateData);

      this.dialogUpdating = true;

      let result = await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Admin/ScheduleDelete",
        {
          id: this.itemTemplateData.id,
          notifications: this.deleteNotifications,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );

      // console.warn(result);

      this.dialogUpdating = false;
      this.deleteNotifications = false;

      if (result.data.success) {
        this.itemTemplateData = null;
        this.scheduleDeleteDialog = false;
        await this.getData();
      } else {
        result.data.errors.forEach((element) => {
          this.$toast.open({
            message: element,
            type: "error",
          });
        });
      }
    },
    onCloseScheduleDelete(event) {
      this.scheduleDeleteDialog = false;
      this.deleteNotifications = false;
      this.itemTemplateData = null;
    },
    async onUpdateSchedule() {
      /*
      console.warn({
        onUpdateSchedule: this.itemTemplateData,
      });
      */

      this.dialogUpdating = true;

      let result = await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Admin/ScheduleUpdate",
        {
          id: this.itemTemplateData.id,
          meeting: this.$route.params.id,
          title: this.itemTemplateData.title,
          color: this.itemTemplateData.color,
          notifications: this.itemTemplateData.notifications,
          location: this.meeting.data
            .find((x) => x.date == this.itemTemplateData.date)
            .rooms.find((x) => x.id == this.itemTemplateData.room).name,
          room: this.itemTemplateData.room,
          date: this.itemTemplateData.date,
          time: {
            from: this.itemTemplateData.time.from,
            to: this.itemTemplateData.time.to,
          },
        },
        {
          headers: {
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );

      /*
      console.warn({
        onSaveScheduleNew: result,
      });
      */

      this.dialogUpdating = false;

      if (result.data.success) {
        this.itemTemplateData = null;
        this.scheduleUpdateDialog = false;
        await this.getData();
      } else {
        result.data.errors.forEach((element) => {
          this.$toast.open({
            message: element,
            type: "error",
          });
        });
      }
    },
    onCloseScheduleUpdate() {
      this.scheduleUpdateDialog = false;
      this.itemTemplateData = null;
    },
    async onMoveSchedule() {
      this.dialogUpdating = true;

      let result = await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Admin/ScheduleMove",
        {
          id: this.itemTemplateData.id,
          meeting: this.$route.params.id,
          location: this.meeting.data
            .find((x) => x.date == this.itemTemplateData.date)
            .rooms.find((x) => x.id == this.itemTemplateData.room).name,
          room: this.itemTemplateData.room,
          notifications: this.itemTemplateData.notifications,
          date: this.itemTemplateData.date,
          time: {
            from: this.itemTemplateData.time.from,
            to: this.itemTemplateData.time.to,
          },
        },
        {
          headers: {
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );

      this.dialogUpdating = false;

      if (result.data.success) {
        this.itemTemplateData = null;
        this.scheduleMoveDialog = false;

        this.$toast.open({
          message: "Item moved",
          type: "success",
        });

        await this.getData();
      } else {
        result.data.errors.forEach((element) => {
          this.$toast.open({
            message: element,
            type: "error",
          });
        });
      }
    },
    onCloseScheduleMove() {
      this.scheduleMoveDialog = false;
      this.itemTemplateData = null;
    },
    getMeetingDateRooms(date) {
      let meetingDate = this.meeting.data.find((x) => x.date == date);

      /*
      console.warn({
        rooms: meetingDate.rooms,
      });
      */

      return meetingDate.rooms;
    },
    onLocations(event) {
      // console.warn(event);

      this.itemTemplateLocations = event;

      this.locationsDialog = true;
    },
    onCloseLocations() {
      this.locationsDialog = false;
      this.itemTemplateLocations = null;
    },
    async locationUpdate(event) {
      /*
      console.warn({
        locationUpdate: event,
      });
      */

      this.dialogUpdating = true;

      let result = await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Admin/MeetingRoomUpdate",
        {
          room: event,
          meetingId: this.$route.params.id,
          date: this.itemTemplateLocations.date,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );

      this.dialogUpdating = false;

      if (result.data.success) {
        // this.locationsDialog = false;
        // this.itemTemplateLocations = null;
        this.itemTemplateLocations.rooms = result.data.data;

        this.$toast.open({
          message: "Item updated",
          type: "success",
        });

        await this.getData();
      } else {
        result.data.errors.forEach((element) => {
          this.$toast.open({
            message: element,
            type: "error",
          });
        });
      }
    },
    async locationDelete(event) {
      /*
      console.warn({
        locationDelete: event,
      });
      */

      this.dialogUpdating = true;

      let result = await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Admin/MeetingRoomDelete",
        {
          room: event,
          meetingId: this.$route.params.id,
          date: this.itemTemplateLocations.date,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );

      this.dialogUpdating = false;

      if (result.data.success) {
        // this.locationsDialog = false;
        // this.itemTemplateLocations = null;

        this.itemTemplateLocations.rooms = result.data.data;

        this.$toast.open({
          message: "Item deleted",
          type: "success",
        });

        await this.getData();
      } else {
        result.data.errors.forEach((element) => {
          this.$toast.open({
            message: element,
            type: "error",
          });
        });
      }
    },
    async locationCreate() {
      /*
      console.warn({
        locationCreate: this.location,
      });
      */

      this.dialogUpdating = true;

      let result = await this.axios.post(
        Setting[process.env.NODE_ENV].API + "Admin/MeetingRoomAdd",
        {
          room: {
            name: this.location.name,
            order: this.location.order,
            id: null,
          },
          meetingId: this.$route.params.id,
          date: this.itemTemplateLocations.date,
        },
        {
          // withCredentials: true,
          headers: {
            // 'Access-Control-Allow-Credentials':true,
            "Access-Control-Allow-Origin": Setting[process.env.NODE_ENV].Source,
          },
        }
      );

      this.dialogUpdating = false;

      if (result.data.success) {
        this.location = {
          name: null,
          order: null,
        };

        this.itemTemplateLocations.rooms = result.data.data;

        this.$toast.open({
          message: "Location created",
          type: "success",
        });

        await this.getData();
      } else {
        result.data.errors.forEach((element) => {
          this.$toast.open({
            message: element,
            type: "error",
          });
        });
      }
    },
    showRegistrations(event) {
      /*
      console.warn({
        showRegistrations: event.registrations,
      });
      */

      this.scheduleRegistrationsDialog = true;
      this.scheduleRegistrations = event.registrations;
    },
    onCloseRegistrationDialog() {
      this.scheduleRegistrationsDialog = false;
      this.scheduleRegistrations = [];
    },
    getStatus(status) {
      let result = "";
      switch (status) {
        case 0:
          result = "On site";
          break;
        case 1:
          result = "Remote";
          break;
        case 2:
          result = "Canceled";
          break;
      }
      return result;
    },
    onImport(event) {
      this.dayImport = event;

      console.warn(event);

      this.scheduleImportDialog = true;
    },
    async onImportSchedule() {
      this.fileUploadError = false;
      let response = await this.upload(this.file);

      console.warn({
        onImportSchedule: response,
      });

      if (response.data.success) {
        this.scheduleImportDialog = false;
        this.file = null;

        let metting = await this.getMeeting();
        let schedules = await this.getSchedules();

        this.meeting = metting.data.data;
        this.schedules = schedules.data.data;
      } else {
        this.fileUploadError = true;
      }

      console.warn(response);
    },
    onCloseImportDialog() {
      this.dayImport = null;
      this.file = null;
      this.fileUploadError = false;
      this.scheduleImportDialog = false;
    },
    async upload(file) {
      this.$refs.formScheduleUpload.validate();

      if (this.scheduleImportValid) {
        this.scheduleImportLoading = true;

        const formData = new FormData();

        var response = null;

        formData.append("files[]", file);

        response = await this.axios.post(
          Setting[process.env.NODE_ENV].API +
            "Schedule/UploadSchedule?meetingId=" +
            this.$route.params.id +
            "&date=" +
            this.dayImport.date,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.scheduleImportLoading = false;
      }

      return response;
    },
  },
  async created() {
    await this.getData();
  },
  async mounted() {
    if (!this.store.getAuth) {
      this.$router.push("/");
    }
  },
};
